import { useBasicFontSize } from './components/Utilities';
import Banjo from './assets/images/banjo.png';
import Logo27 from './assets/images/27logo.png';
import WCC from './assets/images/WCC.png';
// Footer
// 27th logo
// Links: www.discoverdunmore.com, delcg.ltd
// Waterford CCC logo
// border
// Bluegrass favicon

// Fancy banjo fretboard designs?

function App() {
  document.body.style.margin = "0px";
  document.body.style.overflowX = "hidden";
  // document.body.style.overflowY = "hidden";

  const fonts = ['Durango', 'Carnevalee', 'Showboat', 'Quentin Caps'];

  const Act = (props) => {

    const text = props.text.replaceAll(' ', '\xA0') + ' ';
    const fontSize = Math.min(useBasicFontSize() / props.text.length * 140, 40);
    const showboatWordSpacing = '-' + (fontSize / 2) + 'px';

    return (
      <>
        <div
          style={{
            fontSize: fontSize + 'px',
            lineHeight: fontSize + 20 + 'px',
            fontFamily: fonts[props.fontNumber],
            wordSpacing: props.fontNumber === '2' ? showboatWordSpacing : 'auto',
            display: 'inline',
          }}>
          {text}
        </div>
        {/* <img src={Banjo} alt='' height='auto' width='30px' style={{
          marginRight: '20px',
          }}/> */}
      </>
    );
  }

  return (
    <div
      style={{
        margin: "0px",
        height: '100%',
        width: '100%',

      }}
    >
      <div style={{
        position: 'absolute',
        left: '0px',
        top: '0px',
      }}>
        <header>
          <div style={{
            width: '100vw',
            fontFamily: 'Showboat',
            fontSize: useBasicFontSize() * 6 + 'px',
            lineHeight: useBasicFontSize() * 8 + 'px',
            textAlign: 'center',
          }}>
            Dunmore East
          </div>
          <div style={{
            width: '100vw',
            fontFamily: 'Durango',
            fontSize: useBasicFontSize() * 9 + 'px',
            lineHeight: useBasicFontSize() * 9 + 'px',
            textAlign: 'center',
          }}>
            Bluegrass Festival
          </div>
          <div style={{
            width: '100vw',
            fontFamily: 'Carnevalee',
            fontSize: useBasicFontSize() * 8 + 'px',
            lineHeight: useBasicFontSize() * 10 + 'px',
            textAlign: 'center',
          }}>
            26 27 28 August 2022
          </div>
        </header>
        <div style={{
          textAlign: 'center',
          maxWidth: '96vw',
          marginLeft: '2vw',
          marginBottom: '5vw',
        }}>
          <div>
            <img src={Banjo} alt='' height='auto' width='auto' />
          </div>
          <Act text='The Blueberry Pickers' fontNumber='3' />
          <Act text='Shane Hennessy' fontNumber='0' />
          <Act text='Woodbine' fontNumber='2' />
          <Act text='Prison Love' fontNumber='1' />
          <Act text='The Backyard Band' fontNumber='3' />
          <Act text='The Blue Light Smugglers' fontNumber='0' />
          <Act text='Kiss My Grass' fontNumber='2' />
          <Act text='Pilgrim St' fontNumber='1' />
          <Act text='Long Way Home' fontNumber='0' />
          <Act text='Whistle' fontNumber='3' />
          <Act text='Mons Wheeler Band' fontNumber='1' />
          <div>
            <img src={Banjo} alt='' height='auto' width='auto' />
          </div>
        </div>
        <div style={{
          textAlign: 'center',
          fontFamily: 'Roboto',
          fontSize: Math.min(useBasicFontSize() * 5, 30) + 'px',
        }}>

          <a href='http://www.discoverdunmore.com'>
            www.discoverdunmore.com
          </a>
          <div style={{
            fontWeight: 'bold'
          }}>
            <a href="mailto:bluegrass@eircom.net">bluegrass@eircom.net</a>
          </div>
          <img src={Logo27} alt='' height='auto' style={{
            minWidth: '30%',
            margin: '5vw',
          }} />
          <div>
            <img src={WCC} alt='' style={{
              minWidth: '30%',
            }} />
          </div>
          <div style={{
            minWidth: '30%',
          }}>
            detcg.ltd
          </div>
          
        {/* <div>
          Information:
          <address>
            Creadan, Dunmore East, Waterford, Ireland X91 W7T8
            <br />
            <a href="mailto:bluegrass@eircom.net">bluegrass@eircom.net</a>
            <br />
            <span style={{ marginRight: "10px" }}>☎</span>
            <a href="tel:+35351878832">+353 51 - 87 88 32</a>
          </address>
        </div> */}
        {/* <hr /> */}
        {/* <div>
          <h3>
            Page held for client by{" "}
            <a href="https://www.yourapp.ie">YourApp.ie</a>.
          </h3>
          <p>
            Websites, mobile apps, social media
            <br />
            Supporting Irish SMEs
            <br />
            Free initial consultation:{" "}
            <a href="tel:+353876202101">(087) 6 202 101</a>
            <br />
            Outside Republic of Ireland:{" "}
            <a href="tel:+353876202101">+353 876 202 101</a>
          </p>
        </div> */}
      </div>
        <div style={{
          width: '97%',
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'flex-end',
          fontSize: '12px',
          paddingBottom: '6px',
        }}>
          <div style={{
            backgroundColor: 'white',
          }}>
            ©2022 Site designed and maintained by <a href="http://yourapp.ie">YourApp.ie</a>&nbsp;
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
